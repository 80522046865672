//
// session-timeout.scss
//
#session-timeout-dialog {
    .close {
        display: none;
    }
    .countdown-holder {
        color: $danger;
        font-weight: $fw-medium;
    }
    .btn-default {
        background-color: $white;
        color: $danger;
        box-shadow: none;
    }
}