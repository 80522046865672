table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
  padding-right: 30px;
}
table.dataTable thead>tr>th:active,
table.dataTable thead>tr>td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}
div.dataTables_scrollFoot>.dataTables_scrollFootInner {
  box-sizing: content-box;
}
div.dataTables_scrollFoot>.dataTables_scrollFootInner>table {
  margin-top: 0 !important;
  border-top: none;
}
@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-sm>thead>tr>th {
  padding-right: 20px;
}
table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}
table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}
div.table-responsive>div.dataTables_wrapper>div.row {
  margin: 0;
}
div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
  padding-left: 0;
}
div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
  padding-right: 0;
}
.alignRich {
  text-align: right;
}
.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
  background: blue;
  letter-spacing: 1px;
  td {
    text-align: right;
  }
}
.TablaIngreso {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
    width: 100px;
  }
  table td:nth-child(2) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(5) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(6) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(7) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
    width: 120px  !important;
  }
  table td:nth-child(8) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}

.TablaEgreso {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(5) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(6) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(7) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(8) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaHorasLaboradas {
  table td:nth-child(1) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(5) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(6) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(7) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(8) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(9) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaProcesados {
  table td:nth-child(1) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
    width: 140px;
  }
  table td:nth-child(2) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(5) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(6) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(7) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaTarifa {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: right;
    width: 130px;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(5) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.HeadBlue {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #032D5E;
  color: white;
  border: solid rgb(235, 235, 235)
}
#TablaProcesados label {
  position: relative;
}
#TablaProcesados input {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.TablaFlujos {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(5) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaFases {
  table td:nth-child(0) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(4) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaTransiciones {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaTareas {
  table td:nth-child(5) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaListas {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
    width: 150px;
  }
  table td:nth-child(2) {
    text-align: left;
    width: 80px;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(3) {
    text-align: right;
    width: 80px;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaListasNuevo {
  table td:nth-child(1) {
    text-align: left;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(2) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
    width: 120px;
  }
}
.TablaMaestro {
  table td:nth-child(5) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.TablaUsuarios {
  table td:nth-child(6) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}
.input__search {
  margin-bottom: 2rem;
  font-size: 1rem;
  padding: .5rem 1rem;
  border: 1px solid #151515;
  border-radius: 8px;
}
.input__search::placeholder {
  font-family: 'Amatic SC', cursive;
}
.fruits {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.hide {
  display: none;
}
.TablaClientes {
  table td:nth-child(8) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}

.TablaCorreos {
  table td:nth-child(4) {
    text-align: center;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
  table td:nth-child(6) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}

.TablaCuentaBancaria{
  table td:nth-child(6) {
    text-align: right;
    padding-Top: 10px;
    padding-Bottom: 1px;
  }
}

@media (max-width: 400px) {
  .mediaRow {
    width: 100% !important;
  }
}
.mediaRow {
  width: 32% !important;
}
.colorTitulo {
  color: #aa1b0E !important;
  font-size: 32px !important;
  text-transform: none !important;
}
.contenedor-divs {
  align-items: center;
  width: 50%;
}