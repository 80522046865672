.my-custom-scrollbar {
    position: relative;
    height: 600px;
    overflow: auto;
}
.table-wrapper-scroll-y {
    display: block;
    max-height: 600px;
}
.table-wrapper-scroll-y-costos thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #032D5E;
    color: white;
    border: 1px solid #dfd2d2
}

.table-wrapper-scroll-y-costos tbody tr td {
    border: 1px solid inherit
}
.table table-bordered .TablaHorasLaboradas thead th {
    border: solid;
}

.titulo {
    font-family: roboto;
    background: white;
    color: #454545;
    color: #000;
    font-weight: 900;
}

.grillado-caso {
    font-family: roboto;
    display: grid;
    grid-template-columns: 100%;
    /*grid-gap: 10px;*/
    padding: 0px;
}
.grillado-caso-codigo {
    font-family: roboto;
    display: grid;
    grid-template-columns: 100%;
    /*grid-gap: 10px;*/
    padding: 0px;
}
.grillado-liq {
    font-family: roboto;
    display: grid;
    grid-template-columns: 25% 75%;
}
.combodate {
    width: 135px;
}
.options-ec {
    font-family: roboto;
    display: grid;
    grid-template-columns: 15% 15% 15% 8% auto;
    grid-gap: 5px;
    padding-top: 0px;
}

.options-ec-combo {
    font-family: roboto;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 8% auto;
    grid-gap: 5px;
    padding-top: 0px;
}
.boton-procesar {
    display: grid;
    justify-content: start;
    margin-top: auto;
}

.options-liq {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    grid-gap: 10px;
    padding: 10px;
}
.botones-actions {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    grid-gap: 5px;
}

.casos {
    font-family: roboto;
    display: grid;
    grid-template-columns: 20% 80%;


}

.casos-ie {
    font-family: roboto;
    display: grid;
    grid-template-columns: 8% auto;
    justify-content: center;


}
.texto-casos {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}
.total {
    /*margin-top: 40px;*/
    text-align: right;
    margin-top: 15px;


}
.saldo-t {
    padding: 2px;
    border-radius: 5px;
    text-align: right;

}
.boton-excel {
    text-align: right;
    padding-right: 2.7%;
}
.excel {
    position: absolute;
    font-size: 13px;
    color: rgb(255, 255, 255);
    margin-left: 30px;
    margin-top: 8px;
    width: 200px;
    display: inline-block;
}
.grid-egreso {
    display: grid;
    grid-template-columns: 75% 25%;
}
.icon-caso {
    text-align: left;
    color: #1F60AB;
    display: grid;
    justify-content: center;
}
.cab-caso {
    display: grid;
    justify-content: start;
}
.icon-caso-cuenta {
    color: #1F60AB;
}
.color-excel {
    background-color: #95AB1F;
    border: 1px solid #95AB1F
}
.botones {
    display: grid;
    grid-template-columns: 100%;

}
.botones-sep {
    display: grid;
    justify-content: end;
    grid-template-columns: 33% 33% auto;
    grid-gap: 5px;
    margin-top: auto;

}
.btn-danger:hover {
    color: #fff;
    background-color: #5e1711;
    border-color: #5e1711;
}
.icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-size: cover;
    background-color: black;
}
.icon-bike {
    background-image: url("../Egresos/cli.svg");
}
.icon-bike:hover {
    background-image: url("../Egresos/cli.svg");
}
.form-notificaciones {
    padding: 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 10px;
}
.col-notif {
    display: grid;
    grid-template-columns: 15% 25% auto;
    grid-gap: 15px;
}
.col-notif-start {
    display: grid;
    grid-template-columns: 100%;
    justify-content: start;
}
.col-notif-end {
    display: grid;
    grid-template-columns: 100%;
    justify-content: end;
}
.space-text {
    letter-spacing: 0.15em;
    font-weight: 0;
}

.checkbox-notificacion {
    text-align: left;
    width: 20px;
    height: 20px;
}
.checkbox-notificacion input:checked~.checkmark {
    background-color: blue;
}
.form-notificaciones div b {
    font-weight: 600;
}
.title-notif {
    font-family: roboto;
    border-bottom: solid #032D5E;
    font-weight: bold;
}
.texto-notif {
    font-family: roboto;
    font-size: 13px
}

@font-face {
    font-family: roboto;
    src: url("Roboto-Medium.ttf");
}
.texto-combex {
    font-family: roboto;
}
.border-abajo {
    border-bottom: groove #032D5E;
}
.form-usuario {
    display: grid;
    grid-template-columns: 30% auto 30%;
    grid-gap: 10px;
}
.form-boton-save {
    display: grid;
    margin-top: auto;
    justify-content: end;
    /* justify-self: center; */
    /* margin-top: -20px;*/
    margin-right: 20px;
}
.combos-cliente {
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: 10px;
}
.combos-doc {
    display: grid;
    grid-template-columns: 30% 30% auto;
    grid-gap: 10px;
}
.grid-filter-email {
    display: grid;
    grid-template-columns: 10% 45% 45%;
    grid-gap: 5px;
}
.filter-title {
    font-size: 20px;
}
.grid-fechas {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0;
}
.button-filter {
    display: grid;
    /* align-items: end; */
    /* justify-content: end; */
    padding: auto;
    /* justify-content: center; */
    align-items: top;
    margin-top: auto;
    width: 30%;
}