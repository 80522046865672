.my-custom-scrollbar-costos {
    
    position: relative;
    overflow: auto;
    }
    .table-wrapper-scroll-y-costos {
    display: block;
    
    
    }

    .table-wrapper-scroll-y-costos thead th { position: sticky; top: 0; z-index: 1; background-color: #032D5E;color: white;border: 1px solid #dfd2d2 }

    .table-wrapper-scroll-y-costos tbody tr td { border: 1px solid inherit }
    .table table-bordered .TablaHorasLaboradas  thead th { border: solid;}
