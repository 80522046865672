// 
// _table.scss
// 
.table {
  th {
      font-weight: $font-weight-bold;
  }
  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}
.table-bordered {
  border: 1px  solid  #dfd2d2;
  th,td {
    border: 1px  solid  #dfd2d2;
}
}

.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:   #dfd2d2;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color:  #dfd2d2;
}
