@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?p6bn5n');
  src:  url('fonts/icomoon.eot?p6bn5n#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?p6bn5n') format('truetype'),
    url('fonts/icomoon.woff?p6bn5n') format('woff'),
    url('fonts/icomoon.svg?p6bn5n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-aprobado:before {
  content: "\e900";
}
.icon-balanza:before {
  content: "\e901";
}
.icon-calendario:before {
  content: "\e902";
}
.icon-campana:before {
  content: "\e903";
}
.icon-Casos:before {
  content: "\e904";
}
.icon-clientes:before {
  content: "\e905";
}
.icon-configuracion:before {
  content: "\e906";
}
.icon-Dashboard:before {
  content: "\e907";
}
.icon-Descargar:before {
  content: "\e908";
}
.icon-docuemntos-2:before {
  content: "\e909";
}
.icon-documento-nuevo:before {
  content: "\e90a";
}
.icon-documentos-copy:before {
  content: "\e90b";
}
.icon-Documentos:before {
  content: "\e90c";
}
.icon-documentos:before {
  content: "\e90d";
}
.icon-edificio:before {
  content: "\e90e";
}
.icon-Editar:before {
  content: "\e90f";
}
.icon-egresos-copy:before {
  content: "\e910";
}
.icon-Egresos:before {
  content: "\e911";
}
.icon-egresos:before {
  content: "\e912";
}
.icon-eliminar:before {
  content: "\e913";
}
.icon-esfera-2d:before {
  content: "\e914";
}
.icon-eventos:before {
  content: "\e915";
}
.icon-eventos1:before {
  content: "\e916";
}
.icon-eventos2:before {
  content: "\e917";
}
.icon-excel:before {
  content: "\e918";
}
.icon-filtro:before {
  content: "\e919";
}
.icon-Flor-de-Liz:before {
  content: "\e91a";
}
.icon-Flujo:before {
  content: "\e91b";
}
.icon-horas-laboradas:before {
  content: "\e91c";
}
.icon-horas-laboradas1:before {
  content: "\e91d";
}
.icon-Horas-Laboradas2:before {
  content: "\e91e";
}
.icon-icono-location:before {
  content: "\e91f";
}
.icon-Ingresos-copy:before {
  content: "\e920";
}
.icon-Ingresos .path1:before {
  content: "\e921";
  color: rgb(0, 0, 0);
}
.icon-Ingresos .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ingresos:before {
  content: "\e923";
}
.icon-libro:before {
  content: "\e924";
}
.icon-lupa:before {
  content: "\e925";
}
.icon-Martillo:before {
  content: "\e926";
}
.icon-Mundo:before {
  content: "\e927";
}
.icon-no-ver:before {
  content: "\e928";
}
.icon-nuevo:before {
  content: "\e929";
}
.icon-papelera:before {
  content: "\e92a";
}
.icon-PDF:before {
  content: "\e92b";
}
.icon-Persona:before {
  content: "\e92c";
}
.icon-proceso-de-liquidacion:before {
  content: "\e92d";
}
.icon-Rechazar:before {
  content: "\e92e";
}
.icon-recuperar:before {
  content: "\e92f";
}
.icon-Reloj:before {
  content: "\e930";
}
.icon-reportes:before {
  content: "\e931";
}
.icon-Sobre:before {
  content: "\e932";
}
.icon-solicitar-documento:before {
  content: "\e933";
}
.icon-Subir-Archivos:before {
  content: "\e934";
}
.icon-tarea:before {
  content: "\e935";
}
.icon-tareas:before {
  content: "\e936";
}
.icon-tareas1:before {
  content: "\e937";
}
.icon-ver:before {
  content: "\e938";
}
