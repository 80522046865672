.container-1{
    color: black;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 600px;
    grid-gap: 20px;
    padding: 0;
    margin:0;
   
    
}
.item{
    
    border-radius: 20px;
}
.hijos2{
    display: grid ;
    align-items: center ;
    grid-template-columns: repeat(1, 1fr);
    overflow: scroll;
    height: 65%;
    grid-gap: 10px;
}
.hijos3{
    display: grid ;
    align-items: center ;
    grid-template-columns: repeat(2, 1fr);
    overflow: scroll;
    height: 65%;
    grid-gap: 10px;
}

.c2{
    background-color:rgb(111, 111, 180);
  
    
   
}
.titulo{
    font-weight: 800;
}

.columna{
    font-weight: 600;
}
/*Botón*/
.drop-button{
    background-color:  #8DEBF7;
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /*Botón hover y focus*/
  .drop-button:hover, drop-buttton:hover{
    background-color: #2EABAB;
  }
  
  /*Posición del contenedor*/
  .dropdown{
    position: relative;
    display: inline-block;
   }
  
  /*submenu*/
  .dropdown-content{
    display: none;
    position: absolute;
    min-width: 200px;
    background-color: #e9e9e9;
    z-index: 99999;
  }
  
  .dropdown-content a{
    color: #000000;
    padding: 12px 16px;
    display: block;
  }
  
  .dropdown-content a:hover{
    background-color: #c0c0c0
  }
  .show {
    display: block;
  }
  